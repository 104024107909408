@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');


.navbar {
    background: #131313;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  
  .navbar-container {
    display: flex;
    justify-content: center;
    height: 80px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
  }
  
  .nav-item {
    height: 90px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    padding: 1rem 1rem;
    height: 100%;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 60vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #131313;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      color: #f00946;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
      height: 22%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 6px;
      right: 0;
      transform: translate(-120%, 60%);
      font-size: 2rem;
      cursor: pointer;
    }
  }